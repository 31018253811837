<template>
  <div class="cabinet__item-block">
    <div v-if="profile.form.id === undefined">
      <button class="send primary" @click.prevent="openProfile">Заполнить профиль</button>
    </div>
    <div v-else class="form__unit_inline">
      <div>Ваша последняя анкета на {{ utils.getLocaleDateString(profile.form.date_entered) }}</div>
      <div>
        <button class="primary item" @click.prevent="openProfile">Обновить данные</button>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import utils from '@/utils';
import animation from '@/utils/animation';

export default {
  name: 'CabinetComponent',
  components: {},
  data: function () {
    return {
      utils,
      data: null,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'getProfile',
    }),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({
      pullProfile: 'pullProfile',
    }),
    openProfile(e) {
      this.changeModals({ profile: { visible: true, data: {} } });
      animation.pressing(e);
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.pullProfile();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
