<template>
  <div class="cabinet__description">
    <div v-if="!personalData.form.telegramId">
      <c-label
        hint-text="Наш telegram bot @ptfin_orders_bot позволяет получать и согласовывать рекомендации, отображать состояние портфеля даже при ограниченном интернете"
        text="Отправить приглашение в telegram:"
      />
      <button class="primary" @click.prevent="invite">Пригласить</button>
    </div>
    <div v-else>
      <c-label
        hint-text="Вы уже подключили ваш telegram к @ptfin_orders_bot"
        :text="`<div>Ваш номер telegram: <b>${personalData.form.telegramId}</b></div>`"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import CLabel from '@/components/UI/ComponentLabel.vue';
import animation from '@/utils/animation';

export default {
  name: 'TelegramComponent',
  components: { CLabel },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      personalData: 'getPersonalData',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      inviteTelegram: 'inviteTelegram',
      pullPersonalData: 'pullPersonalData',
    }),
    invite(e) {
      animation.pressing(e);
      this.inviteTelegram();
    },
  },
  watch: {},
  created() {},
  mounted() {
    this.pullPersonalData();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped></style>
