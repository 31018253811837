<template>
  <div class="requisites">
    <div class="banks">
      <div
        v-for="(item, key) in requisites"
        :key="key"
        :class="[{ active: key === activeBankKey }, 'bank-item']"
        @click="showRequisites(key)"
      >
        <div class="logo"><img :src="item.logo" alt="" /></div>
      </div>
    </div>

    <div class="requisites-wrap">
      <div class="requisites-item">
        <h3>Для пополнения внутри банка</h3>
        <!--        <div class="copy" @click="copyContent('internal')">-->
        <!--          <div class="copy-icon"><img :src="icons.copy" alt="" /></div>-->
        <!--        </div>-->
        <div class="req-item">
          <c-label text="Наименование компании:" />
          <div class="req-item__info">Partner Finance Limited</div>
        </div>
        <div class="req-item">
          <c-label text="Текущий счет (IBAN счет):" />
          <div class="req-item__info">{{ bank.IBAN }}</div>
        </div>
        <div class="req-item">
          <c-label text="Банк получателя:" />
          <div class="req-item__info">{{ bank.name }}</div>
        </div>
        <div class="req-item">
          <c-label text="Назначение платежа:" />
          <div class="req-item__info">Agreement No. PF202XXXXX Name / Last Name</div>
        </div>
        <div class="req-item">
          <c-label text="КБЕ:" />
          <div class="req-item__info">{{ bank.KBE }}</div>
        </div>
        <div class="req-item">
          <c-label text="КНП:" />
          <div class="req-item__info">510</div>
        </div>
      </div>

      <div class="requisites-item">
        <h3>Для пополнения через систему SWIFT</h3>
        <!--        <div class="copy" @click="copyContent('swift')">-->
        <!--          <div class="copy-icon"><img :src="icons.copy" alt="" /></div>-->
        <!--        </div>-->
        <div class="req-item">
          <c-label text="Банк получателя SWIFT:" />
          <div class="req-item__info">{{ bank.bankOfPayeeSWIFT }}</div>
        </div>
        <div class="req-item">
          <c-label text="Корреспондентский счет:" />
          <div class="req-item__info">{{ bank.corrAccount }}</div>
        </div>
        <div class="req-item">
          <c-label text="Банк-корреспондент:" />
          <div class="req-item__info">{{ bank.corrBank }}</div>
        </div>
        <div class="req-item">
          <c-label text="Банк-корреспондент SWIFT:" />
          <div class="req-item__info">{{ bank.corrBankSWIFT }}</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { icons, img } from '@/assets/svg-img';
import CLabel from '@/components/UI/ComponentLabel.vue';

export default {
  name: 'CabinetComponent',
  components: { CLabel },
  data: function () {
    return {
      icons,
      activeBankKey: null,
      contentToCopy: '',
      bank: null,
      bankName: '',
      IBAN: '',
      requisites: {
        bereke: {
          name: 'JSC "Bereke Bank" branch in Almaty',
          logo: img.bereke,
          IBAN: 'KZ36914002203US006PL',
          bankOfPayeeSWIFT: 'BRKEKZKA',
          corrAccount: '36459462',
          corrBank: 'CITIBANK N.A. New York, USA',
          corrBankSWIFT: 'CITIUS33',
          KBE: '15',
        },
        forte: {
          name: 'AO ForteBank, Казахстан',
          logo: img.forte,
          IBAN: 'KZ3596502F0015180978',
          bankOfPayeeSWIFT: 'BRKEKZKA',
          corrAccount: '8900548533',
          corrBank: 'The Bank of New York Mellon New York, USA',
          corrBankSWIFT: 'IRVTUS3N',
          KBE: '17',
        },
        freedom: {
          name: 'АО «Банк Фридом Финанс Казахстан»',
          logo: img.freedom,
          IBAN: 'KZ73551B427000323USD',
          bankOfPayeeSWIFT: 'EURASIAN BANK, KAZAKHSTAN',
          corrAccount: 'KZ4894800USD20130021',
          // bankIntermediary: '8900548533',
          corrBank: 'The Bank of New York Mellon New York, USA',
          corrBankSWIFT: 'IRVTUS3N',
          KBE: '17',
        },
      },
    };
  },
  computed: {
    ...mapGetters({}),
  },
  methods: {
    ...mapMutations({
      changeNotifications: 'changeNotifications',
    }),
    ...mapActions({}),
    copyContent(type) {
      let content = '';
      if (type === 'internal') {
        content = `${this.bank.name}\nТекущий счет (IBAN счет): ${this.bank.IBAN}\nБанк получателя: ${this.bank.name}\nНазначение платежа: Agreement No. PF202XXXXX Name / Last Name\nКБЕ: 17\nКНП: 510`;
      } else if (type === 'swift') {
        content = `Банк получателя SWIFT: ${this.bank.bankOfPayeeSWIFT}\nКорреспондентский счет: ${this.bank.corrAccount}\nБанк-корреспондент: ${this.bank.corrBank}\nБанк-корреспондент SWIFT: ${this.bank.corrBankSWIFT}`;
      }

      const textarea = document.createElement('textarea');
      textarea.value = content;
      textarea.setAttribute('readonly', '');
      textarea.style.position = 'absolute';
      textarea.style.left = '-9999px';
      document.body.appendChild(textarea);

      textarea.select();
      textarea.setSelectionRange(0, content.length);

      try {
        const success = document.execCommand('copy');
        if (success) {
          this.changeNotifications({
            text: 'Содержимое скопировано в буфер обмена.',
            style: 'green',
            type: 'success',
            duration: 2,
          });
        } else {
          // console.error('Копирование не удалось');
        }
      } catch (error) {
        // console.error('Не удалось скопировать содержимое:', error);
      } finally {
        document.body.removeChild(textarea);
      }
    },
    showRequisites(bankKey) {
      const bank = this.requisites[bankKey];
      this.bankName = bank.name;
      this.IBAN = bank.IBAN;
      this.activeBankKey = bankKey;
      this.bank = bank;
    },
  },
  watch: {},
  created() {
    this.showRequisites('bereke');
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.requisites {
  .copy {
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 2px;

    .copy-icon {
      width: 20px;
      height: 20px;
    }
  }

  .banks {
    display: flex;
    margin: 10px 0 20px;
    gap: 0 10px;

    .logo {
      height: 20px;
      width: 80px;
      @include breakpoint-down(md) {
        width: 100%;
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: contain;
      }
    }
  }

  .bank-item {
    border-radius: var(--main-border-radius);
    border: 1px solid var(--input-alt-default-border-outside);
    box-shadow: 0 0 0 rgba(0, 0, 0, 0.6);
    cursor: pointer;
    padding: 8px 12px;
    transition: all 0.2s ease;
    opacity: 0.65;
    transform: translateY(0);
    @include breakpoint-down(md) {
      width: 33%;
    }

    &.active {
      opacity: 1;
      transform: translateY(-2px);
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(225, 225, 225, 0.9);
    }

    &:hover {
      opacity: 1;
      transform: translateY(-2px);
      box-shadow: -5px 5px 10px rgba(0, 0, 0, 0.6);
      border: 1px solid rgba(225, 225, 225, 0.9);
    }
  }
}

.requisites {
  &-wrap {
    display: flex;
    gap: 0 40px;
    @include breakpoint-down(md) {
      flex-direction: column;
      gap: 15px 0;
    }
  }

  &-item {
    font-size: 14px;
    position: relative;
    @include breakpoint-down(md) {
      margin-left: 0;
    }

    h3 {
      margin-bottom: 10px;
      font-weight: 500;
      padding-right: 50px;
    }

    .req-item {
      margin-bottom: 10px;

      &:last-child {
        margin-bottom: 0;
      }

      &__info {
        font-weight: 500;
      }
    }
  }
}
</style>
