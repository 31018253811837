<template>
  <div class="symbol-list">
    <span v-if="user.type === 'manager'">Инструмент добавится в watchlist: {{ selectedWl.toUpperCase() }}</span>
    <div class="search">
      <div class="search-icon"><img :src="icons.searchIcon" alt="" /></div>
      <input v-model="localSearchQuery" v-focus type="text" @input="search" />
    </div>
    <div class="wrap">
      <div v-for="item in localSearchList" :key="item" class="row" @click="instrumentTrackingSwitch(item)">
        <div class="item img-wrap">
          <img v-if="item.logoid" :src="'https://s3-symbol-logo.tradingview.com/' + item.logoid + '.svg'" alt="" />
          <div v-else class="plug-img">
            <img :src="icons.plug" />
          </div>
        </div>
        <div :title="item.symbol" class="item symbol">
          <span>{{ item.symbol }}</span>
        </div>
        <div class="item currency">{{ item.currency_code }}</div>
        <div class="item description">
          <span>{{ item.description }}</span>
        </div>
        <div class="item exchange">{{ item.source }}</div>
        <div class="item add-wrap">
          <!--          <img :src="[watchListSymbols.includes(item.symbol) ? icons.check : icons.plus]" alt="" />-->
          <img :src="[existInstrument(item) ? icons.check : icons.plus]" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';

const focus = {
  mounted: (el) => el.focus(),
};

export default {
  name: 'searchList',
  components: {},
  data() {
    return {
      icons,
      localSearchQuery: '',
      localSearchList: [],
    };
  },
  computed: {
    ...mapGetters({
      searchList: 'getSearchList',
      watchList: 'getWatchList',
      selectedWl: 'getSelectedWl',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapMutations({}),
    ...mapActions({
      fetchInstrument: 'fetchInstrument',
      addInstrumentWL: 'addInstrumentWL',
      deleteInstrumentWL: 'deleteInstrumentWL',
    }),
    search() {
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        // console.log(this.localSearchQuery);
        this.fetchInstrument(this.localSearchQuery);
      }, 600);
    },
    existInstrument(item) {
      return this.watchList.findIndex((each) => each.name === this.selectedWl && each.symbol === item.symbol) !== -1;
    },
    updateList() {
      // console.log('updateList');
      this.localSearchList = [];
      for (const row of this.searchList) {
        // row.source = row.prefix === undefined ? row.exchange : row.prefix;
        // if (['AMEX', 'NASDAQ', 'NYSE'].includes(row.source)) this.localSearchList.push(row);
        this.localSearchList.push(row);
      }
    },
    instrumentTrackingSwitch(item) {
      // console.log('search :', item);
      this.existInstrument(item)
        ? this.deleteInstrumentWL({
            name: this.selectedWl,
            instrument: item,
          })
        : this.addInstrumentWL({ name: this.selectedWl, instrument: item });
    },
  },
  watch: {
    searchList(newState) {
      // console.log('searchList:', newState);
      if (newState.length > 0) this.updateList();
    },
  },
  directives: {
    focus,
  },
  created() {},
  mounted() {
    this.updateList();
  },
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.symbol-list {
  .wrap {
    height: 314px;
    overflow: auto;
    padding-right: 15px;
    margin-right: -15px;
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      right: 15px;
      left: 0;
      height: 1px;
      background: var(--table-totals-divider-line-color);

      @include breakpoint-down(md) {
        background: transparent;
      }
    }

    @include breakpoint-down(md) {
      padding-bottom: 30px;
      height: auto;
    }
  }

  .row {
    display: flex;
    border-bottom: 1px solid var(--table-totals-divider-line-color);
    padding: 5px 0;
    position: relative;
    cursor: pointer;
    align-items: center;

    @include breakpoint-down(md) {
      align-items: center;
      justify-content: space-between;
      padding: 5px 10px;
    }
  }

  .item {
    font-size: 12px;
    color: var(--table-text-color);
    font-weight: 600;
    display: flex;
    align-items: center;
  }

  .plug-img {
    width: 18px;
    height: 18px;
    background: rgba(26, 30, 33, 1);
    padding: 3px;
  }

  .img-wrap {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 5px;
  }

  .symbol {
    margin-right: 5px;

    span {
      width: 80px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .currency {
    width: 40px;
    font-weight: 400;
    margin-right: 10px;
  }

  .description {
    margin-right: 10px;

    span {
      text-transform: lowercase;
      width: 200px;
      font-size: 10px;
      display: -webkit-box;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
    }

    @include breakpoint-down(md) {
      display: none;
    }
  }

  .exchange {
    margin-right: 10px;
  }

  .add-wrap {
    margin-left: auto;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 11px;
    height: 11px;

    @include breakpoint-down(md) {
      margin-left: 0;
      height: 14px;
      width: 14px;
    }

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  .add {
    //display: flex;
    //align-items: center;
    //justify-content: center;
    //width: 11px;
    //height: 10px;
  }

  .search {
    border-bottom: 1px solid var(--table-totals-divider-line-color);
    margin-bottom: 20px;
    position: relative;

    .search-icon {
      position: absolute;
      left: 0;
      bottom: 0;
    }

    input {
      height: 30px;
      width: 100%;
      border: none;
      background: transparent;
      padding: 0 5px 0 25px;

      &:focus {
        outline: none;
      }
    }
  }
}
</style>
