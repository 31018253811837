const { registerIndicator } = require('klinecharts');
exports.registerOrderIndicator = function (order, chart) {
  const orderTime = order.accepted_time.getTime();
  const candles = chart.getDataList();
  if (candles.length === 1) return 'error';
  let indexCandle = candles.findIndex((item) => orderTime - (candles[1].timestamp - candles[0].timestamp) < item.timestamp);
  if (indexCandle === -1) indexCandle = candles.length - 1;
  const color = order.action === 'buy' ? 'rgba(0, 255, 0, 0.6)' : 'rgba(255,0,0, 0.7)';
  const xCoorOrder = indexCandle; // Получили свечу размещения ордера
  const yCoorOrder = order.price ? parseFloat(order.price) : parseFloat(order.stop); // Получили цену ордера
  const textAction = yCoorOrder;
  const name = order.transaction_id.toString();
  registerIndicator({
    name: name,
    draw: ({ ctx, xAxis, yAxis }) => {
      const x = xAxis.convertToPixel(xCoorOrder);
      const y = yAxis.convertToPixel(yCoorOrder);
      ctx.lineWidth = 1;
      ctx.shadowBlur = 8;
      ctx.shadowOffsetY = 2;
      ctx.shadowOffsetX = 2;
      ctx.strokeStyle = color;
      ctx.font = '600 11px "Montserrat"';
      ctx.setLineDash([]);

      ctx.beginPath();
      ctx.moveTo(x, y);
      ctx.lineTo(chart.getSize().width, y);
      ctx.stroke();
      ctx.closePath();

      let textY = order.action === 'buy' ? y + 10 : y - 16;
      ctx.fillStyle = color;
      ctx.fillText(textAction + '', x, textY);

      ctx.fill();
      return false;
    },
  });
  return name;
};
