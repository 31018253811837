<template>
  <div class="options-component">
    <div class="options-component__header">
      <div>
        <div>Опционы</div>
        <div>
          <b>{{ selectedSymbol }}</b>
        </div>
      </div>
      <div>
        <div>текущая цена</div>
        <div>
          <b>{{ utils.formatValue(symbols[selectedSymbol].lp, 'currency') }}</b>
        </div>
      </div>
    </div>
    <div class="filters">
      <div class="filters__item">
        <c-label text="Виды экспираций:" />
        <c-select v-model="selectedSignificant" :options="significants" />
      </div>
      <div class="filters__item">
        <c-label text="Дата экспираци:" />
        <c-select v-model="selectedExpiration" :options="localExpirations" />
      </div>
      <div class="filters__item">
        <c-label text="Диапазон:" />
        <c-select v-model="selectedOptionsRange" :options="optionsRange" />
      </div>
      <div class="filters__item">
        <div class="refresh filters__item-title">
          Обновить
          <div class="icon" @click="refresh">
            <img :class="[waiting ? 'rotation' : '']" :src="icons.refresh" alt="" />
          </div>
        </div>
      </div>
    </div>
    <div class="charts" style="display: flex; justify-content: center">
      <div v-for="chart in charts" :key="chart.name" class="charts__item">
        <c-o-i-chart :lines="chart.data" :title="chart.name" />
      </div>
    </div>
    <div class="options-tables-wrap">
      <div class="options-tables__item left">
        <div class="title">Calls</div>
        <c-table :columnDefs="columns.calls" :name="'calls'" :rows="filterByNearMoney(data.calls)" @dblSelect="selectRow" />
      </div>
      <div class="options-tables__item">
        <c-table :columnDefs="columns.strike" :rows="filterByNearMoney(data.strikes)" />
      </div>
      <div class="options-tables__item right">
        <div class="title">Puts</div>
        <c-table :columnDefs="columns.puts" :name="'puts'" :rows="filterByNearMoney(data.puts)" @dblSelect="selectRow" />
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapMutations } from 'vuex';
import { icons } from '@/assets/svg-img';
import utils from '@/utils';
import CTable from '@/components/UI/ComponentTable';
import COIChart from '@/components/UI/ComponentOptionInfoChart';
import CSelect from '@/components/UI/ComponentSelect.vue';
import CLabel from '@/components/UI/ComponentLabel.vue';

function initialData() {
  return JSON.parse(JSON.stringify({ puts: [], calls: [], strikes: [] }));
}

// function initialExpirations() {
// return JSON.parse(JSON.stringify([{ value: null, significant: 0, text: 'Пусто' }]));
// }

function initialChart({ name, type }) {
  return JSON.parse(
    JSON.stringify({
      name,
      data: { lines: [], axisX: { name: 'strike', type: 'int' }, axisY: { name, type } },
    }),
  );
}

export default {
  name: 'OptionsComponent',
  components: {
    CLabel,
    CSelect,
    CTable,
    COIChart,
  },
  data() {
    return {
      icons,
      columns: {
        calls: [
          { name: 'Код', field: 'symbol_raw', type: 'string', flex: 10 },
          { name: 'Теоретическая цена', field: 'theo', type: 'currency', flex: 4 },
          { name: 'Покупка', field: 'bid', type: 'currency', flex: 4 },
          { name: 'Продажа', field: 'ask', type: 'currency', flex: 4 },
          { name: 'Interest', field: 'open_interest', type: 'number', flex: 4 },
          // { name: 'VI', field: 'volatility', type: 'number', flex: 5 },
          // { name: 'Delta', field: 'delta', type: 'number', flex: 3 },
          // { name: 'Gamma', field: 'gamma', type: 'number', flex: 3 },
          // { name: 'Vega', field: 'vega', type: 'number', flex: 3 },
          // { name: 'Theta', field: 'theta', type: 'number', flex: 3 },
        ],
        strike: [{ name: 'Страйк', field: 'strike', type: 'string', flex: 1 }],
        puts: [
          // { name: 'Theta', field: 'theta', type: 'number', flex: 3 },
          // { name: 'Vega', field: 'vega', type: 'number', flex: 3 },
          // { name: 'Gamma', field: 'gamma', type: 'number', flex: 3 },
          // { name: 'Delta', field: 'delta', type: 'number', flex: 3 },
          // { name: 'VI', field: 'volatility', type: 'number', flex: 5 },
          { name: 'Interest', field: 'open_interest', type: 'number', flex: 4 },
          { name: 'Продажа', field: 'ask', type: 'currency', flex: 4 },
          { name: 'Покупка', field: 'bid', type: 'currency', flex: 4 },
          { name: 'Теоретическая цена', field: 'theo', type: 'currency', flex: 4 },
          { name: 'Код', field: 'symbol_raw', type: 'string', flex: 10 },
        ],
      },
      data: initialData(),
      existDates: {
        selected: null,
        list: [{ value: null, text: 'Пусто' }],
      },
      waiting: false,
      // localVolume: 'all',
      // volume: [
      //   { value: 'all', text: 'All' },
      //   { value: 'ghenerator than 0', text: 'Ghenerator than 0' },
      // ],
      selectedSignificant: '3',
      significants: [
        { value: '1', text: 'Ежедневные и выше' },
        { value: '2', text: 'Еженедельные и выше' },
        { value: '3', text: 'Ежемесячные/Квартальные' },
      ],
      selectedOptionsRange: '3',
      optionsRange: [
        { value: 'all', text: 'Весь список' },
        { value: '9', text: 'Рядом с деньгами 9' },
        { value: '6', text: 'Рядом с деньгами 6' },
        { value: '3', text: 'Рядом с деньгами 3' },
      ],
      selectedExpiration: null,
      // localExpirations: initialExpirations(),
      localExpirations: [],
      charts: {},
      // volatility: initialVolatility(),
      // payments: initialPayments(),
    };
  },
  computed: {
    ...mapGetters({
      symbols: 'getSymbols',
      selectedSymbol: 'getSelectedSymbol',
      expirations: 'getExperations',
      options: 'getOptions',
      modals: 'getModals',
    }),
    utils() {
      return utils;
    },
  },
  methods: {
    ...mapMutations({
      changeOrder: 'changeOrder',
      changeSymbols: 'changeSymbols',
      changeLevelI: 'changeLevelI',
      changeTimeFrameSelected: 'changeTimeFrameSelected',
    }),
    ...mapActions({
      pullExpirations: 'pullExpiration',
      pullOptions: 'pullOptions',
      updateOptions: 'updateOptions',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    getExperations() {
      // console.log('getExperations');
      if (!this.selectedSymbol) return;
      // let symbol = this.selectedSymbol;
      const isOption = this.selectedSymbol.match(/^([A-Za-z]{1,5})(\d{6})([CP])(\d{8})$/);
      if (isOption !== null) this.updateSelectedSymbol(isOption[1]);
      this.pullExpirations({ symbol: this.selectedSymbol });
    },
    getOptons() {
      if (!this.waiting) {
        this.waiting = true;
        this.data = initialData();
        this.chartsClear();
        this.pullOptions({ symbols: [this.selectedSymbol], expirations: [this.selectedExpiration] });
      }
    },
    chartsClear() {
      this.charts['volatility'] = initialChart({ name: 'volatility', type: 'float' });
      this.charts['delta'] = initialChart({ name: 'delta', type: 'float' });
      this.charts['theta'] = initialChart({ name: 'theta', type: 'float' });
      this.charts['vega'] = initialChart({ name: 'vega', type: 'float' });
      this.charts['payment'] = initialChart({ name: 'payment', type: 'float' });
    },
    fillArrays(newState) {
      this.data = initialData();
      this.chartsClear();
      const set = new Set();
      for (const option of newState) {
        const each = { data: option };
        for (const field of ['symbol_raw', 'volatility', 'delta', 'gamma', 'vega', 'theta', 'theo', 'open_interest', 'bid', 'ask']) {
          if (field === 'symbol_raw') each.symbol = option[field];
          each[field] = option[field];
        }
        set.add(option.strike);
        option.type === 'C' ? this.data.calls.push(each) : this.data.puts.push(each);
      }
      const calls = this.data.calls.map((each) => each.data);
      const puts = this.data.puts.map((each) => each.data);
      this.charts['volatility'].data.lines.push({ name: 'calls', data: calls }, { name: 'puts', data: puts });
      this.charts['delta'].data.lines.push({ name: 'calls', data: calls }, { name: 'puts', data: puts });
      this.charts['theta'].data.lines.push({ name: 'calls', data: calls }, { name: 'puts', data: puts });
      this.charts['vega'].data.lines.push({ name: 'calls', data: calls }, { name: 'puts', data: puts });

      const payments = { calls: [], puts: [], total: [] };
      for (const strike of set) {
        const callsP = this.calcCalls(strike);
        const putsP = this.calcPuts(strike);
        payments.calls.push({ payment: callsP, strike });
        payments.puts.push({ payment: putsP, strike });
        payments.total.push({ payment: callsP + putsP, strike });

        this.data.strikes.push({ strike, data: { strike } });
      }
      this.charts['payment'].data.lines.push(
        { name: 'calls', data: payments.calls },
        { name: 'puts', data: payments.puts },
        { name: 'total', data: payments.total },
      );
    },
    filterByNearMoney(options) {
      if (this.selectedOptionsRange === 'all') return options;
      const instrument = this.symbols[this.selectedSymbol];
      const lessStrike = options.filter((option) => parseFloat(option.data.strike) / 1000 < parseFloat(instrument.lp));
      const moreStrike = options.filter((option) => parseFloat(option.data.strike) / 1000 > parseFloat(instrument.lp));

      return [...moreStrike.slice(-this.selectedOptionsRange), ...lessStrike.slice(0, this.selectedOptionsRange)];
    },
    listAvailableExpirations() {
      this.localExpirations = [];
      let setSelectedExpiration = true;
      for (const each of this.expirations) {
        if (parseInt(each.significant) >= parseInt(this.selectedSignificant)) {
          if (this.selectedExpiration === each.expiration) setSelectedExpiration = false;
          this.localExpirations.push({
            value: each.expiration,
            significant: each.significant,
            text: utils.getLocaleDateString(each.expiration),
          });
        }
      }
      if (setSelectedExpiration && this.localExpirations.length > 0) this.selectedExpiration = this.localExpirations[0].value || null;
    },
    calcCalls(strike) {
      let amount = 0.0;
      for (let i = this.data.puts.length - 1; i >= 0; i--) {
        if (parseInt(this.data.calls[i].data.strike) < parseInt(strike)) {
          amount += (this.data.calls[i].data.open_interest * (parseInt(strike) - parseInt(this.data.calls[i].data.strike))) / 10;
        } else {
          return amount;
        }
      }
      return amount;
    },
    calcPuts(strike) {
      let amount = 0.0;
      for (let i = 0; i < this.data.calls.length; i++) {
        if (parseInt(this.data.puts[i].data.strike) > parseInt(strike)) {
          amount += (this.data.puts[i].data.open_interest * (parseInt(this.data.puts[i].data.strike) - parseInt(strike))) / 10;
        } else {
          return amount;
        }
      }
      return amount;
    },
    async refresh() {
      this.waiting = true;
      await this.updateOptions(this.selectedSymbol);
      this.waiting = false;
    },
    selectRow(name, data) {
      const option = this.data[name].find((item) => item.symbol_raw.toString() === data.symbol.toString());
      const optionSymbol = option.data.symbol_raw;
      const levelI = {
        symbol: optionSymbol,
        bid: option.data.bid,
        bid_size: option.data.bid_size,
        ask: option.data.ask,
        ask_size: option.data.ask_size,
      };
      this.symbols[optionSymbol] = this.createInstrument(option.data);
      this.changeTimeFrameSelected(86400);
      this.changeSymbols(this.symbols);
      this.updateSelectedSymbol(optionSymbol);
      this.changeOrder({ quantity: 1, tif: 'day', type: 'limit', limitPrice: parseFloat(option.data.theo).toFixed(2) });
      this.changeLevelI(levelI);
      this.modals.options.visible = false;
    },
    createInstrument(option) {
      option.asset_category = 'OPT';
      option.symbol = option.symbol_raw;
      option.option_type = option.type;
      option.type = 'option';
      delete option.symbol_raw;
      delete option.bid;
      delete option.bid_size;
      delete option.ask;
      delete option.ask_size;
      return option;
    },
  },
  watch: {
    selectedSignificant() {
      this.listAvailableExpirations();
    },
    // selectedSymbol(newSymbol) {
    //   if (newSymbol) {
    //     this.getExperations();
    //   }
    // },
    expirations: {
      handler(newState) {
        if (newState.length > 0) {
          this.listAvailableExpirations();
        }
        //  else {
        // this.localExpirations = initialExpirations();
        // this.selectedExpiration = 0;
        // }
      },
      deep: true,
    },
    selectedExpiration(newState, oldState) {
      if (newState !== oldState) this.getOptons();
    },
    options: {
      handler(newState) {
        if (newState.length > 0) {
          this.fillArrays(newState);
          this.waiting = false;
        }
      },
      deep: true,
    },
    // localExpirations(newState, oldState) {
    //   console.log(JSON.stringify(newState.selected), JSON.stringify(oldState.selected));
    //   // if (newState.selected !== oldState.selected)
    //   this.pullOptions();
    // },
  },
  created() {
    this.getExperations();
    // this.getOptons();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.options-component {
  &__header {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 25px;
    font-size: 16px;
    gap: 0 20px;

    & > div {
      gap: 0 10px;
      display: flex;
      align-items: center;
    }
  }

  .filters {
    display: flex;
    align-items: flex-end;
    margin-bottom: 30px;
    gap: 30px;

    &__item {
      flex: 1;
    }
  }

  .charts {
    display: flex;
    gap: 15px;
    margin-bottom: 30px;

    &__item {
      width: 200px;
      height: 200px;
    }
  }

  .options-tables {
    &-wrap {
      display: flex;
      justify-content: center;
      align-items: flex-end;
    }

    &__item {
      margin: 2px;

      &.left,
      &.right {
        flex: 2;
      }

      .title {
        margin-bottom: 5px;
        text-align: center;
        font-size: 20px;
        font-weight: 600;
      }
    }
  }

  .refresh {
    display: flex;
    font-size: 14px;
    justify-content: flex-end;
    align-items: center;

    .icon {
      width: 25px;
      height: 25px;
      border-radius: var(--main-border-radius);
      border: solid 1px var(--table-totals-divider-line-color);
      transition: all 0.3s ease-in-out;
      box-shadow: 0 1px 1px var(--table-header-shadow-color);
      background: var(--input-alt-default-bg);
      margin-left: 4px;
      padding: 6px;
      flex-shrink: 0;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;

      &:hover {
        box-shadow: -1px 1px 3px var(--table-header-shadow-color);
        transform: translate(0, -1px);
      }

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        transition: all 0.2s ease-in-out;

        &.rotation {
          animation-name: rotate;
          animation-duration: 2s;
          animation-iteration-count: infinite;
          animation-timing-function: linear;
        }

        @keyframes rotate {
          from {
            transform: rotate(0deg);
          }
          to {
            transform: rotate(360deg);
          }
        }
      }
    }
  }
}
</style>
