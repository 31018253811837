<template>
  <div class="order-table-component">
    <c-table :columnDefs="columnDefs" :rows="localOrders" :setSort="setSort" title="Orders" @cancel="cancelOrder" @select="selectRow" />
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import CTable from '@/components/UI/ComponentTable';

export default {
  name: 'order-table-component',
  components: {
    CTable,
  },
  data() {
    return {
      localOrders: [],
      setSort: { field: 'time', order: 'desc' },
      columnDefs: [
        { name: '', field: 'cancel', hidden: true },
        { name: 'Тикер', field: 'symbol', type: 'string', sort: true, search: 'text', flex: 1 },
        { name: 'Счет', field: 'account', type: 'string', flex: 1 },
        { name: 'Дата принятия', field: 'time', type: 'datetime', sort: true, flex: 2 },
        { name: 'Тип', field: 'type', type: 'string', sort: true, flex: 1 },
        { name: 'Статус', field: 'status', type: 'string', sort: true, search: 'text', flex: 2 },
        {
          name: 'Количество',
          field: [
            { field: 'quantity', type: 'number' },
            { field: 'filled', type: 'number' },
          ],
          type: 'composite',
          sort: true,
          flex: 1,
        },
        {
          name: 'Цена',
          field: [
            { field: 'price', type: 'currency' },
            { field: 'stop', type: 'currency' },
          ],
          type: 'composite',
          sort: true,
          flex: 1,
        },
        { name: 'Номер приказа', field: 'transaction_id', type: 'string', sort: true, flex: 1 },
      ],
      details: {},
      detailColumn: [
        { name: 'Время сделки', field: 'date_time', type: 'datetime', flex: 1 },
        { name: 'Цена сделки', field: 'price', type: 'currency', flex: 1 },
        { name: 'Количество', field: 'quantity', type: 'number', flex: 1 },
        { name: 'Сумма сделки', field: 'money', type: 'currency', flex: 1 },
        // { name: 'Комиссия', field: 'commission', type: 'currency', flex: 1 },
        { name: 'Номер сделки', field: 'transaction_id', type: 'string', flex: 1 },
      ],
    };
  },
  computed: {
    ...mapGetters({
      orders: 'getOrders',
      order: 'getOrder',
      orderStatus: 'getOrderStatus',
      orderType: 'getOrderType',
      user: 'getUser',
      accounts: 'getAccounts',
    }),
  },
  methods: {
    ...mapMutations({
      setDefaultOrder: 'setDefaultOrder',
      changeOrder: 'changeOrder',
      changeModals: 'changeModals',
    }),
    ...mapActions({
      cancelOrder: 'cancelOrder',
      updateSelectedSymbol: 'updateSelectedSymbol',
    }),
    getSymbol(symbol) {
      return symbol;
    },
    getType(serverType) {
      return this.orderType[serverType];
    },
    getStatus(serverStatus) {
      return this.orderStatus[serverStatus];
    },
    selectRow(data) {
      this.updateSelectedSymbol(data.symbol);
      const order = this.localOrders.find((item) => item.key.toString() === data.key.toString());
      if (order.data.status === 'approval' && this.user.id === this.accounts[order.account].contact_id) {
        this.changeModals({ orderConfirm: { visible: true, data: order.data } });
      }
      const action = ['filled', 'part_filled'].includes(order.data.status)
        ? order.data.action === 'buy'
          ? 'sell'
          : 'buy'
        : order.data.action;
      this.setDefaultOrder();
      this.changeOrder({
        action,
        type: order.data.type === 'stop_limit' ? 'stop' : order.data.type,
        quantity: order.data.quantity,
        limitPrice: order.data.price || order.data.stop_price || 0.0,
        triggerPrice: order.data.stop || 0.0,
      });
    },
  },
  watch: {
    orders: {
      handler(newState) {
        this.localOrders = [];
        for (const each of newState) {
          const detailsRow = [];
          for (const detail of each.trades) {
            detailsRow.push({
              key: detail.id,
              date_time: detail.date_time,
              price: detail.price,
              quantity: detail.quantity,
              money: detail.money,
              // commission: detail.commission,
              transaction_id: detail.transaction_id,
            });
          }
          this.localOrders.push({
            key: each.id,
            account: each.account,
            time: each.accepted_time,
            type: this.getType(each.type),
            symbol: this.getSymbol(each.instrument.symbol),
            status: this.getStatus(each.status),
            quantity: each.quantity,
            filled: each.filled,
            price: each.price || null,
            stop: each.stop || null,
            transaction_id: each.transaction_id,
            commission: each.commission,
            tif: each.tif,
            time_extended: each.time_extended,
            details: { column: this.detailColumn, row: detailsRow },
            data: each,
            deletedRow: each.canceled,
          });
        }
      },
      deep: true,
    },
  },
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '../style/helper/index';

.order-table-component {
  height: 100%;
}
</style>
