/* eslint-disable no-unused-vars */
import { createApp } from 'vue';
import App from '@/App.vue';
import router from '@/router';
import store from '@/store';
import { Metacom } from './connect/metacom.js';
import VueTelInput from 'vue-tel-input';
import 'vue-tel-input/vue-tel-input.css';
import '@vuepic/vue-datepicker/dist/main.css';

import './registerServiceWorker';

class Application {
  constructor() {
    const protocol = process.env.VUE_APP_PROTOCOL === 'http:' ? 'ws' : 'wss';
    const options = { reconnectTimeout: 1000 };
    this.metacom = Metacom.create(`${protocol}://${process.env.VUE_APP_SERVER_HOST}:${process.env.VUE_APP_SERVER_PORT}/api`, options);
  }
}

window.addEventListener('load', async () => {
  window.application = new Application();
  window.api = window.application.metacom.api;
  await window.application.metacom.load('auth', 'example', 'files', 'registration');

  const token = localStorage.getItem('session.token');
  // console.log('load', token);
  let logged = false;
  if (token) {
    const res = await window.api.auth.restore({ token });
    logged = res.status === 'logged';
  }
  // if (!logged) router.push('/login');

  await window.application.metacom.load('alpaca', 'marketData', 'ptfin', 'settings', 'watchList', 'info', 'instruction', 'user');

  const app = createApp(App);

  app.use(router).use(store).use(VueTelInput, { mode: 'auto' }).mount('#app');
});

document.addEventListener('visibilitychange', function (data) {
  // console.log(document.visibilityState, data);
  if (document.visibilityState === 'visible') window.application.metacom.open();
});
