<template>
  <div class="authentication">
    <div class="authentication-form">
      <div class="wrapper-inner">
        <div class="logo">
          <img :src="img.blueLogo" alt="" />
        </div>
        <form v-if="show" class="form" @submit.prevent="signIn">
          <div v-bind:class="status.error ? 'error' : 'success'">{{ status.text }}</div>
          <div :hidden="requireCode" class="form__unit">
            <c-label hint-text="Ваш номер телефона является вашим логином, после регистрации" text="Login" />
            <input id="username" v-model="form.login" placeholder="login" required title="Login" type="text" />
          </div>
          <div :hidden="requireCode" class="form__unit">
            <c-label text="Password" />
            <input
              id="password"
              v-model="form.password"
              autocomplete="off"
              placeholder="Password"
              required
              title="Password"
              type="password"
            />
          </div>
          <div :hidden="!requireCode" class="form__unit">
            <c-label hint-text="Введите код из смс" text="Code" />
            <input
              id="code"
              v-model="form.code"
              autocomplete="off"
              placeholder="Code"
              title="Code"
              type="text"
              @input="checkCode($event.target)"
            />
          </div>
          <div class="form__footer">
            <button :hidden="requireCode" class="primary center" type="submit" @click="animate">Login</button>
          </div>
        </form>

        <form v-else class="form" @submit.prevent="sendForgot">
          <div class="form__unit">
            <c-label hint-text="Укажите email который привязан к вашему аккаунту" text="Введите email" />
            <input v-model="email" autocomplete="email" required type="email" />
          </div>
          <c-label text=" Нет доступа к почте? Свяжитесь с менеджером" />

          <div class="form__footer">
            <button class="primary center" type="submit" @click="animate">Отправить</button>
          </div>
        </form>

        <div class="hint-link">
          <div>
            <div v-show="status.error" @click="show = !show">восстановить пароль</div>
          </div>
          <router-link v-show="show" :to="{ name: 'registration' }">открыть счет</router-link>
        </div>
      </div>

      <c-modal :visible="modals.infoPass.visible" size="small" title="Изменение пароля" @close="modals.infoPass.visible = false">
        <div class="info">
          Новый пароль отправлен на почту <br />
          <span>{{ email }}</span
          >.
        </div>
      </c-modal>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapMutations } from 'vuex';
import animation from '@/utils/animation';
import { img } from '@/assets/svg-img';
import CLabel from '@/components/UI/ComponentLabel.vue';
import CModal from '@/components/UI/ComponentModal.vue';

export default {
  name: 'auth-view',
  components: { CModal, CLabel },
  data() {
    return {
      img,
      requireCode: false,
      form: { login: '', password: '', code: '' },
      status: { text: '', error: false },
      email: '',
      show: true,
    };
  },
  computed: {
    ...mapGetters({
      modals: 'getModals',
      user: 'getUser',
    }),
  },
  methods: {
    ...mapMutations({
      changeModals: 'changeModals',
    }),
    ...mapActions({
      login: 'login',
      resetState: 'resetState',
      forgotPassword: 'forgotPassword',
    }),
    animate(e) {
      animation.pressing(e);
    },
    sendForgot() {
      this.forgotPassword({ login: this.form.login, email: this.email });
      this.changeModals({ infoPass: { visible: true, data: {} } });
      this.show = !this.show;
      this.status.error = false;
      this.status.text = '';
    },
    async signIn() {
      this.status = { text: 'Try!!!', error: false };
      if (this.form.login.at(0) === '8') this.form.login = '+7' + this.form.login.slice(1);
      const result = await this.login({ login: this.form.login, password: this.form.password, code: this.form.code });
      if (result.status === 'totp') {
        this.requireCode = true;
        this.status = { text: result.text, error: false };
      }
      if (result.status === 'logged') this.$router.push({ name: 'index' });
      this.status = { text: result.text, error: true };
    },
    checkCode(code) {
      code.value = code.value.replace(/\D+/g, '');
      if (code.value.length === 6) this.signIn();
    },
  },
  watch: {},
  created() {
    this.resetState();
  },
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
.hint-link {
  padding-top: 20px;
  display: flex;
  justify-content: space-between;
  font-size: 12px;

  a {
    color: rgba(163, 166, 204, 1);
  }

  & > div {
    color: rgba(163, 166, 204, 1);
    cursor: pointer;
  }
}

.info {
  font-size: 16px;
  margin-top: 20%;
  text-align: center;

  span {
    font-size: 18px;
    color: rgba(255, 160, 122, 0.6);
  }
}
</style>
