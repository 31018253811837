<template>
  <div class="block-title">
    {{ title }}
    <!--  Название instrument это общее название для слота, так как я думаю в будущем будет ещё какой-то функционал  -->
    <slot name="instrument"></slot>
  </div>
</template>

<script>
export default {
  name: 'ComponentBlockTitle',
  components: {},
  data() {
    return {};
  },
  props: {
    title: {
      type: String,
    },
  },
  computed: {},
  methods: {},
  watch: {},
  created() {},
  mounted() {},
  unmounted() {},
};
</script>

<style lang="scss" scoped>
@import '@/style/helper/index';

.block-title {
  color: var(--widget-header-text-color);
  background-image: linear-gradient(to right, var(--widget-header-grad-color-1), var(--widget-header-grad-color-2));
  box-shadow: 0 1px 0 0 var(--widget-header-shadow-color);
  font-weight: 600;
  padding: 5px 10px;
  text-transform: capitalize;
  font-size: 12px;
  margin-bottom: 1px;
  display: flex;

  @include breakpoint-down(md) {
    font-size: 16px;
  }
}
</style>
