export default {
  initialPortfolio() {
    return JSON.parse(
      JSON.stringify({
        items: [],
        date_time: null,
        total: { assets: 0.0, cashes: 0.0, amount: 0.0, bp: 0.0 },
      }),
    );
  },
  initialOrder() {
    return JSON.parse(
      JSON.stringify({
        deal: {
          general: {
            symbol: null,
            quantity: 100.0,
            description: '',
            date_time: null,
          },
          orders: [
            {},
            {
              action: 'buy',
              type: 'limit',
              tif: 'gtc',
              limitPrice: 0.0,
              triggerPrice: 0.0,
            },
          ],
        },
        list: [
          {
            account_id: null,
            contact_id: null,
            contract_id: null,
          },
        ],
        confirmation: { order: false, short: false },
      }),
    );
  },
  initialSymbolData(data) {
    return JSON.parse(
      JSON.stringify({
        symbol: data.symbol,
        source: data.listing_exchange === 'ARCA' ? 'AMEX' : data.listing_exchange,
        asset_category: data.asset_category,
        currency: data.currency || 'USD',
        lp: data.lp || 0.0,
        ch: data.ch || 0.0,
      }),
    );
  },
  initialLevelI(symbol) {
    return JSON.parse(JSON.stringify({ symbol, bid: null, bid_size: null, ask: null, ask_size: null }));
  },
  initialNotification({ type = 'toast', style = 'default', text = '', duration = 1.5 }) {
    return JSON.parse(JSON.stringify({ type, style, text, duration }));
  },
  initialAccount(account = {}) {
    const data = { account_id: null, contact_id: null, contract_id: null, full_name: null };
    for (const key in account) data[key] = account[key];
    return JSON.parse(JSON.stringify(data));
  },
  initialState() {
    return JSON.parse(
      JSON.stringify({
        token: localStorage.getItem('session.token') || '',
        user: {
          id: null,
          login: null,
          type: null,
        },
        personalData: { form: {}, options: {} },
        profile: { form: {}, options: {} },
        documents: [],
        modals: {
          boarding: { visible: false, data: {} },
          about: { visible: false, data: {} },
          profile: { visible: false, data: {} },
          options: { visible: false, data: {} },
          orderConfirm: { visible: false, data: {} },
          shortConfirm: { visible: false, data: {} },
          cabinet: { visible: false, data: {} },
          infoPass: { visible: false, data: {} },
        },
        notifications: [],
        confirmation: true,
        totp: null,
        accounts: {},
        accountSelected: null,
        timeFrameSelected: null,
        timeUpdateTerminal: null,
        intervalNumber: null,
        symbols: {},
        selectedSymbol: null,
        levelI: {},
        portfolio: this.initialPortfolio(),
        orders: [],
        options: [],
        experations: [],
        orderStatus: {
          error: 'Ошибка',
          created: 'Создан',
          approval: 'На согласовании',
          pending: 'Активен',
          accepted: 'Принят',
          part_filled: 'Частично исполнен',
          filled: 'Исполнен',
          cancelling: 'Отменяется',
          cancelled: 'Отменен',
          expired: 'Истек',
          inactive: 'Не активен',
          invalid: 'Поврежден',
        },
        orderType: { market: 'Рыночная', limit: 'Лимитированная', stop: 'СТОП', stop_limit: 'СТОП с Лимитом' },
        order: this.initialOrder(),
        selectedWl: 'main',
        watchList: [],
        searchList: [],
        chartData: [],
        chartType: '',
        chartMainIndicators: [],
        chartSubIndicator: '',
        updateWatchList: true,
        widthDisplay: 932,
        chartHeight: true,
        // removeIndicator: null,
      }),
    );
  },
};
